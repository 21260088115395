import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Books about Badass Women in WWII History",
  "author": "Ashley",
  "date": "2020-03-04T08:30:46.000Z",
  "categories": ["Diversity and Inclusion", "Women's History Month"],
  "slug": "books-about-badass-women-in-wwii-history",
  "draft": false,
  "meta_title": "Books about Badass Women in WWII History",
  "cover": "../../images/wp_blog_images/ww2-54-l.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When it comes to picking books to read or put on my Want to Read list, I always gravitate towards historical fiction and historical non-fiction. This strikes me as strange since I absolutely loathed history as a student.`}</p>
    <p>{`Lately, I have been driven to read about World War II and the badass women who played a role during this era; all because of a book club I attended. I fell head over heels for these heroines and found myself finishing the book, always wanting to read more.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.6875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAR3AJ0Yo/8QAGBABAAMBAAAAAAAAAAAAAAAAAQACAxP/2gAIAQEAAQUCpa4msdCcmcbDP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAICAgMAAAAAAAAAAAAAAAABESECECJBkf/aAAgBAQAGPwIvVPsnFyzkl4f/xAAaEAEAAwADAAAAAAAAAAAAAAABABEhMUFh/9oACAEBAAE/IbJx4w0MX4wPORMDMDsSUWd9QFLsR//aAAwDAQACAAMAAAAQoA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAeEAEBAAEDBQAAAAAAAAAAAAABEQAhUbExQWHR8P/aAAgBAQABPxC9vWB3l23wIq/jjFQdSxQTGBANNSe8MikuiPxhENl1Dzn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "ww2 54 l",
          "title": "ww2 54 l",
          "src": "/static/9e3df3a25d1f18f2bea77f835a5f4a2a/eea4a/ww2-54-l.jpg",
          "srcSet": ["/static/9e3df3a25d1f18f2bea77f835a5f4a2a/cb69c/ww2-54-l.jpg 320w", "/static/9e3df3a25d1f18f2bea77f835a5f4a2a/c08c5/ww2-54-l.jpg 640w", "/static/9e3df3a25d1f18f2bea77f835a5f4a2a/eea4a/ww2-54-l.jpg 1280w", "/static/9e3df3a25d1f18f2bea77f835a5f4a2a/8d810/ww2-54-l.jpg 1388w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{` National Archives`}</p>
    <p>{`In honor of Women’s History Month, I wanted to provide my top 5 books about Badass Women in WWII History. Some of these books are based on real heroines and their factual stories while others are based around general groups and information about these women during the war.`}</p>
    <ol>
      <li parentName="ol"><em parentName="li">{`The Alice Network`}</em>{` by Kate Quinn. Following two females, one who is a spy for the Alice Network (a factual spy network in France!) and the other is an American searching for her cousin after the war has ended. Their stories collide and bring the audience together while reading about their courage and redemption.`}</li>
      <li parentName="ol"><em parentName="li">{`Lilac Girls`}</em>{` by Martha Hall Kelly. Inspired by the life of a real WWII heroine, this story discusses secrets, love, and redemption. Stories collide between a Polish teenager, a young German doctor, and a socialite American. The stories cross continents and show the striving of justice for those that history has forgotten.`}</li>
      <li parentName="ol"><em parentName="li">{`The Nightingale`}</em>{` by Kristin Hannah. This book enthralls you with two sisters who take two very different paths during the invasion of France during WWII. One hopelessly waits for her husband as he has gone to war while the other follows her rebellious passion. This book kept me on my toes, was gut-wrenching, and left me mourning for the characters when I put it down.`}</li>
      <li parentName="ol"><em parentName="li">{`The Huntress`}</em>{` by Kate Quinn. A Russian Bomber pilot who joined the forces with an English journalist to find a Nazi war criminal who has hidden away for years. This book discusses the “Night Witches” of the 588th Night Bomber Regiment of the Soviet Air Forces during WWII. As a pilot’s wife, I loved hearing about the antics the female pilots created during the war.`}</li>
      <li parentName="ol"><em parentName="li">{`Code Name: Lise. The True Story of the Woman Who Became WWII’s Most Highly Decorated Spy`}</em>{` by Larry Loftis. Odette Sansom decides to follow in her father’s war hero footsteps by becoming a Special Operations Executive agent to aid Britain and her beloved homeland, France. An innate love story follows in this book with completed missions, capture, and a thrilling cat and mouse game between the spies and the Germans.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      